@tailwind base;
@tailwind components;
@tailwind utilities;

@media screen and (min-width: 768px) {
  .ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    min-height: 60vh;
    min-width: 40rem;
    max-width: 40rem;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .ck.ck-editor__editable:not(.ck-editor__nested-editable) {
    min-height: 60vh;
    width: 80vw;
    margin-bottom: 20px;
  }
  /* ( 크롬, 사파리, 오페라, 엣지 ) 동작 */
  ::-webkit-scrollbar {
    display: none;
  }
  body {
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
