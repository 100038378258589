.loadingImg {
  animation: hourglass 2s ease-in-out infinite;
}

.loadingText {
  animation: flashing 2s ease-in-out infinite;
}

@keyframes hourglass{
  0%{transform:rotate(0deg);}
  100%{transform:rotate(360deg);}
}

@keyframes flashing{
  0%{opacity: 0;}
  50%{opacity: 1;}
  100%{opacity: 0;}
}